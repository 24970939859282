.grid {
  height: 40px;
  width: 40px;
}

.startNode {
  background-color: red;
}

.endNode {
  background-color: blue;
}

.unvisitedNode {
  background-color: lightgray;
}

.visitedNode {
  background-color: purple;
  animation-name: visited;
  animation-duration: 2s;
}

.pathNode {
  background-color: yellow;
  animation-name: path;
  animation-duration: 2s;
}

.wallNode {
  background-color: darkgray;
}

@keyframes visited {
  from {
    background-color: lightgray;
  }
  to {
    background-color: purple;
  }
}
@keyframes path {
  from {
    background-color: purple;
  }
  to {
    background-color: yellow;
  }
}